$navbar-burger-size: 28px;

// TODO: Check this here later
.container > .navbar .navbar-brand {
    @include desktop {
        @at-root html[dir='rtl'] & {
            margin-left: unset;
        }
    }
}

.navbar {
    @include touch {
        border-top: 5px solid $primary;
    }

    &-brand .icon-trolley {
        @at-root html[dir='ltr'] & {
            margin-right: 1rem;
        }

        @at-root html[dir='rtl'] & {
            margin-left: 1rem;
        }

        .content {
            display: none;
        }
    }

    &-burger {
        span {
            height: 2px;
            left: auto;
            right: calc(50% - calc(#{$navbar-burger-size} / 2));

            &:nth-child(1) {
                margin-top: -1px;
            }

            &:nth-child(3) {
                margin-top: 1px;
            }
        }

        &.is-active span {
            width: #{$navbar-burger-size};

            &:nth-child(1) {
                transform: translateY(6px) rotate(45deg);
            }

            &:nth-child(3) {
                transform: translateY(-6px) rotate(-45deg);
            }
        }

        &:not(.is-active) span {
            &:nth-child(1) {
                width: calc(#{$navbar-burger-size} - 10px);
            }

            &:nth-child(2) {
                width: $navbar-burger-size;
            }

            &:nth-child(3) {
                width: calc(#{$navbar-burger-size} - 8px);
            }
        }
    }

    &-dropdown {
        font-size: 1rem;

        &.is-boxed {
            @include desktop {
                margin-top: 4px;
            }
        }

        .navbar-item {
            @include desktop {
                padding: 0.5rem 1.5rem;
            }
        }

        a.navbar-item.is-active {
            color: $text;
            font-weight: 600;
        }
    }

    &-end {
        @include touch {
            border-top: 3px solid $light;

            &.has-background-primary {
                background-color: transparent !important;
            }

            .has-text-weight-normal {
                font-weight: inherit !important;
            }

            .has-text-white {
                color: $text !important;
            }
        }
    }

    &-link {
        &.icon-switched {
            .icon:before {
                background-image: minus($primary);
            }
        }

        &:not(.icon-switched) {
            .icon:before {
                background-image: plus($text);
            }
        }

        &.is-active {
            font-weight: 600;
        }
    }

    &-menu {
        @include touch {
            box-shadow: none;
        }

        @include desktop {
            display: grid;
            grid-template-areas: 'top' 'bottom';
            grid-template-columns: auto;
            grid-template-rows: auto;

            .navbar-end {
                grid-area: top;
                justify-content: unset;
                margin: 0;
                position: relative;
            }

            .navbar-end:after,
            .navbar-end:before {
                background-color: $primary;
                content: '';
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: -1;
            }

            .navbar-end:after {
                right: -100%;
            }

            .navbar-end:before {
                left: -100%;
            }

            .navbar-start {
                grid-area: bottom;
                justify-content: flex-end;
                margin-right: 0;
                padding-bottom: calc(1rem / 8);
                padding-top: calc(1rem / 8);
            }
        }
    }

    &-item {
        @include touch {
            font-weight: bold;

            .navbar-item {
                font-weight: initial;
            }
        }

        &.is-active {
            font-weight: 600;
        }
    }

    .dropdown .button {
        border: 0;
    }

    .icon-chevron-down:before {
        @include desktop {
            background-image: chevron-down($white);
        }
    }

    .is-hoverable {
        &:hover .navbar-link:after {
            background-color: $primary;
            left: -1px;
            transition: background-color, left 86ms;
        }

        .navbar-link:after {
            background-color: transparent;
            bottom: 0;
            content: '';
            height: 4px;
            left: 10rem;
            position: absolute;
            transition: background-color, left 86ms;
            width: 50px;
        }
    }

    .link.has-text-white {
        &:focus,
        &:hover {
            color: $white !important;
            text-decoration: underline;
        }
    }

    .logo {
        @include touch {
            @at-root html[dir='ltr'] & {
                margin-left: $column-gap;
            }

            @at-root html[dir='rtl'] & {
                margin-right: $column-gap;
            }

            svg {
                height: 45px;
                width: auto;
            }
        }

        @include desktop {
            left: 0;
            position: absolute;
            bottom: 0;
        }
    }

    .py-5 {
        &.navbar-item,
        &.navbar-link {
            @include touch {
                padding-bottom: 0.75rem !important;
                padding-top: 0.75rem !important;
            }
        }
    }
}

.navbar-burger {
    @at-root html[dir='ltr'] & {
        margin-left: auto;
        margin-right: 0;
    }

    @at-root html[dir='rtl'] & {
        margin-left: 0;
        margin-right: auto;
    }
}
