.button {
    &.is-icon {
        background-color: transparent;
        border: 0;
        padding: 0;

        .icon {
            margin: 0 !important;
        }
    }

    &.is-paypal-direct {
        background-color: #ffc33a;
        color: $text;
        font-weight: 500;

        @extend #{'.is-primary'};

        &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='56.38 11.5 127.75 33.63'%3E%3Cpath fill='%23009CDE' fill-rule='evenodd' d='M178.1 12.3l-4 26.1c-.1.6.3 1 .8 1h4c.7 0 1.2-.4 1.3-1.2l3.9-25.7c.1-.6-.3-1-.8-1h-4.4c-.4.2-.8.4-.8.8zM165.6 30c-.4 2.7-2.7 4.4-5.3 4.4-1.3 0-2.4-.4-3.2-1.2-.7-.8-1-2-.8-3.2.4-2.7 2.7-4.4 5.3-4.4 1.3 0 2.4.4 3.2 1.2.7.8 1 2 .8 3.2zm6.6-9h-4.8c-.4 0-.8.3-.8.7l-.2 1.3-.3-.4c-1-1.4-3.3-2-5.7-2-5.2 0-9.8 3.9-10.7 9.3-.4 2.8.2 5.3 1.8 7.1 1.4 1.7 3.6 2.3 6 2.3 4.2 0 6.6-2.7 6.6-2.7l-.2 1.3c-.1.4.3.9.8.9h4.3c.7 0 1.2-.4 1.3-1.1l2.6-15.9c.2-.4-.2-.8-.7-.8zm-28 0c-.6 3.6-3.3 3.6-6 3.6h-1.6l1.1-6.7c.1-.4.4-.7.8-.7h.7c1.8 0 3.6 0 4.4 1 .6.6.9 1.6.6 2.8zm-1.2-9.3h-10.1c-.7 0-1.3.4-1.3 1.2l-4.1 25.6c-.1.6.3 1 .8 1h5.2c.4 0 .9-.3 1-.8l1.1-7.2c.1-.7.7-1.2 1.3-1.2h3.2c6.7 0 10.6-3.2 11.6-9.6.4-2.8 0-4.9-1.3-6.4-1.4-1.7-3.9-2.6-7.4-2.6z' clip-rule='evenodd'/%3E%3Cpath fill='%23003087' fill-rule='evenodd' d='M126.6 20.6h-4.9c-.4 0-.9.2-1.1.6l-6.7 9.6-2.9-9.2c-.2-.6-.8-1-1.3-1h-4.8c-.6 0-1 .6-.8 1.1l5.3 15.2-5 6.9c-.4.6 0 1.3.7 1.3h4.9c.4 0 .9-.2 1.1-.6l16.1-22.6c.5-.7.1-1.3-.6-1.3zM94.5 30c-.4 2.7-2.7 4.4-5.3 4.4-1.3 0-2.4-.4-3.2-1.2-.7-.8-1-2-.8-3.2.4-2.7 2.7-4.4 5.3-4.4 1.3 0 2.4.4 3.2 1.2.6.8 1 2 .8 3.2zm6.6-9h-4.8c-.4 0-.8.3-.8.7l-.3 1.3-.3-.4c-1-1.4-3.3-2-5.7-2-5.2 0-9.8 3.9-10.7 9.3-.4 2.8.2 5.3 1.8 7.1 1.4 1.7 3.6 2.3 6 2.3 4.2 0 6.6-2.7 6.6-2.7l-.2 1.4c-.1.4.3.9.8.9h4.3c.7 0 1.2-.4 1.3-1.1l2.8-15.9c.1-.5-.3-.9-.8-.9zm-28 0c-.6 3.6-3.3 3.6-6 3.6h-1.6l1.1-6.7c.1-.4.4-.7.8-.7h.7c1.8 0 3.6 0 4.4 1 .6.6.8 1.6.6 2.8zm-1.2-9.3H61.8c-.7 0-1.3.4-1.3 1.2l-4.1 25.6c-.1.6.3 1 .8 1H62c.7 0 1.3-.4 1.3-1.2l1.1-6.9c.1-.7.7-1.2 1.3-1.2h3.2c6.7 0 10.6-3.2 11.6-9.6.4-2.8 0-4.9-1.3-6.4-1.3-1.6-3.9-2.5-7.3-2.5z' clip-rule='evenodd'/%3E%3C/svg%3E");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            height: inherit;
            margin-left: 0.5rem;
            width: 70px;
        }

        &:focus,
        &:hover {
            background-color: #cc9007;
            color: inherit;
        }
    }

    &.is-primary {
        min-height: var(--button-min-height);
        min-width: var(--button-min-width);
    }

    &.is-wide {
        min-width: 300px;
    }
}
