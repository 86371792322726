$radio-size: 16px;

.control-radio {
    &.is-danger {
        .label:before {
            border-color: $danger;
        }
    }

    &.is-success {
        .label:before {
            border-color: $success;
        }
    }

    .help {
        margin-top: -0.5em;
        padding-left: calc(#{$checkbox-size} + calc(#{$radio-size} / 4));
    }

    input[type='radio'] {
        opacity: 0;
        position: absolute;

        &:checked + label {
            &:after {
                background-color: $primary;
            }

            &:before {
                border-color: $primary;
            }
        }
    }

    label {
        line-height: $checkbox-size;
        padding-left: calc(#{$checkbox-size} + calc(#{$radio-size} / 4));
        position: relative;

        &:after,
        &:before {
            border-radius: 50%;
            content: '';
            position: absolute;
        }

        &:after {
            height: calc(#{$radio-size} / 2);
            left: calc(#{$radio-size} / 2);
            top: calc(calc(#{$radio-size} / 2) + 1px);
            width: calc(#{$radio-size} / 2);
        }

        &:before {
            border: 2px solid $dark;
            height: $radio-size;
            left: calc(#{$radio-size} / 4);
            top: calc(calc(#{$radio-size} / 4) + 1px);
            width: $radio-size;
        }

        a {
            font-weight: bold;
        }

        .asterisk,
        p {
            display: inline;
        }
    }
}
