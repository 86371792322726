.link-block {
    display: block;
    font-size: 0;
    height: 100%;
    left: 0;
    position: absolute;
    text-indent: -9999px;
    top: 0;
    width: 100%;
    z-index: 1;
}
