.pagination {
    @at-root .is-grid & {
        margin-top: 1rem;
    }

    @at-root .is-list & {
        margin-top: 1rem;
    }

    &-link {
        border-radius: 50%;
        border-width: 2px;

        &.is-current {
            @extend #{'.has-text-weight-semibold', '.has-text-white'};
        }

        &:hover {
            background-color: $primary;
            @extend #{'.has-text-weight-semibold'};
        }
    }

    &-next,
    &-previous {
        border-radius: $tag-radius;
        border-width: 2px;
        padding: 1.425em 2em;
        @extend #{'.is-uppercase'};

        &:hover {
            background-color: $primary;
            @extend #{'.has-text-weight-semibold'};
        }
    }
}
