%has-object-fit-contain {
    .image img {
        object-fit: contain;
    }
}

%has-object-fit-cover {
    .image img {
        object-fit: cover;
    }
}

.has-object-fit-contain {
    @extend %has-object-fit-contain;
}

.has-object-fit-cover {
    @extend %has-object-fit-cover;
}
