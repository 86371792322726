.field > .label {
    text-transform: uppercase;
}

.select {
    width: 100%;

    &:not(.is-multiple):not(.is-loading):after {
        border-bottom: 1px solid $anthracite;
        border-left: 1px solid $anthracite;
        border-radius: 0;
        height: 15px;
        margin-top: -0.75rem;
        width: 15px;
    }

    select {
        border-color: $grey-lighter;
        border-radius: 0;
        border-width: 0 0 2px 0;
        padding-left: 0;
        width: 100%;
    }
}
