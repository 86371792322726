a.link-social {
    color: currentColor;
    text-decoration: none;

    // .icon {
    //     border-radius: 100%;
    //     display: block;
    //     height: 32px;
    //     line-height: 32px;
    //     text-align: center;
    //     width: 32px;
    //
    //     &:before {
    //         background-repeat: no-repeat;
    //         background-size: contain;
    //         background-position: center;
    //         content: "";
    //         display: block;
    //         height: inherit;
    //         width: inherit;
    //     }
    //
    //     &-facebook:before {
    //         background-image: facebook($white);
    //     }
    //
    //     &-instagram:before {
    //         background-image: instagram($white);
    //     }
    //
    //     &-twitter:before {
    //         background-image: twitter($white);
    //     }
    //
    //     &-youtube:before {
    //         background-image: youtube($white);
    //     }
    // }
}
