:root {
    --toolbar-square: 60px;
}

.toolbar {
    display: none;

    @at-root .active-toolbar {
        height: 100vh;
        overflow: hidden;
    }

    @include from($tablet) {
        display: block;
    }

    &-icon,
    &-label {
        align-items: center;
        background-color: $orange;
        display: flex;
        height: var(--toolbar-square);
        justify-content: center;
    }

    &-icon {
        width: 60px;

        .icon {
            height: 2em;
            width: 2em;
        }
    }

    &-item {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 4px;
        position: fixed;
        width: var(--toolbar-square);
        z-index: 2;

        @at-root html[dir='ltr'] & {
            right: 0;
        }

        @at-root html[dir='rtl'] & {
            left: 0;
        }

        &:nth-child(1) {
            top: 200px;

            .toolbar-content {
                height: calc(100vh - 200px);
            }
        }

        &:nth-child(2) {
            top: 265px;

            .toolbar-content {
                height: calc(100vh - 265px);
            }
        }

        &:nth-child(3) {
            top: 330px;
        }

        &:nth-child(4) {
            top: 395px;
        }

        &:nth-child(5) {
            top: 460px;
        }

        &:hover {
            width: auto;

            .toolbar-icon,
            .toolbar-label {
                background-color: $orange-darker;
            }

            .toolbar-label {
                font-size: 1rem;

                @at-root html[dir='ltr'] & {
                    padding-right: 10px;
                }

                @at-root html[dir='rtl'] & {
                    padding-left: 10px;
                }
            }
        }

        &.show {
            width: auto;
            z-index: 41;

            &:before {
                background: rgba(0, 0, 0, 0.4);
                bottom: 0;
                content: '';
                position: fixed;
                top: 0;
                z-index: 40;
                width: 100%;
            }

            .toolbar-icon,
            .toolbar-content,
            .toolbar-label {
                z-index: 41;
            }

            .toolbar-icon .icon:before {
                background-image: close(white) !important;
            }

            .toolbar-content {
                display: block;
            }

            .toolbar-label {
                display: none;
            }
        }

        > a {
            color: $white;
            display: flex;
        }
    }

    &-label {
        font-size: 0;
        font-weight: bold;
        text-transform: uppercase;
    }

    &-content {
        display: none;
        max-width: 100%;
        overflow-y: auto;
        overscroll-behavior-y: none;
        width: 500px;
    }

    &-filter {
        background-color: $white;
        bottom: 0;
        height: 100vh;
        overflow-y: scroll;
        padding: 80px;
        position: fixed;
        top: 0;
        width: inherit;

        .button.is-primary {
            min-width: auto;
        }

        li {
            align-items: center;
            display: flex;
            flex-direction: row;
            margin: 0.75em 0;
        }

        &-label {
            flex-grow: 1;
        }
    }

    &-filter-category {
        .field {
            position: relative;

            @at-root html[dir='ltr'] & {
                padding-left: calc(0.5em + 12px);
            }

            @at-root html[dir='rtl'] & {
                padding-right: calc(0.5em + 12px);
            }

            &:before {
                background-color: var(--catColor);
                border-radius: 50%;
                bottom: 0;
                content: '';
                display: block;
                height: 12px;
                margin: auto;
                position: absolute;
                top: 0;
                width: 12px;

                @at-root html[dir='ltr'] & {
                    left: 0;
                }

                @at-root html[dir='rtl'] & {
                    right: 0;
                }
            }
        }
    }

    &-filter-option.toolbar-filter-links {
        a {
            align-items: center;
            display: inline-flex;
            justify-content: center;
            vertical-align: middle;

            &.is-active path {
                fill: $primary;
            }

            + a {
                position: relative;

                @at-root html[dir='ltr'] & {
                    margin-left: 1em;
                }

                @at-root html[dir='rtl'] & {
                    margin-right: 1em;
                }

                &:before {
                    background-color: $light;
                    content: '';
                    display: block;
                    height: 38px;
                    position: absolute;
                    width: 2px;

                    @at-root html[dir='ltr'] & {
                        left: calc((2px + 0.5em) * -1);
                    }

                    @at-root html[dir='rtl'] & {
                        right: calc((2px + 0.5em) * -1);
                    }
                }
            }
        }
    }

    &-filter-group {
        margin-bottom: 2.5em;
        padding-top: 2.5em;

        &-title {
            color: #6e6e6e;
            @extend #{'.has-text-weight-light', '.is-uppercase'};
        }

        + form,
        + .toolbar-filter-group {
            border-top: 2px solid $light;
        }

        .control-checkbox {
            @at-root html[dir='ltr'] & {
                padding-left: 0;
            }

            @at-root html[dir='rtl'] & {
                padding-right: 0;
            }

            input[type='checkbox'],
            .label:before {
                @at-root html[dir='ltr'] & {
                    left: unset;
                    right: 0;
                }

                @at-root html[dir='rtl'] & {
                    left: 0;
                    right: unset;
                }
            }
        }

        .field {
            margin-top: 5px;
            width: 100%;
        }
    }

    .control .toolbar-toggle {
        @at-root html[dir='ltr'] & {
            margin-right: 3rem;
        }

        @at-root html[dir='rtl'] & {
            margin-left: 3rem;
        }
    }

    .label {
        color: $text;
    }
}
