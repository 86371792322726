.field > .label {
    @extend #{'.is-uppercase'};
}

.input {
    border: 0;
    border-bottom: 2px solid $grey-lighter;
    border-radius: 0;
    box-shadow: none;
    // height: 0;
    padding-left: 0;
    padding-top: 0;

    &:focus,
    &.is-danger,
    &.is-success {
        box-shadow: none;
        // height: auto;

        &:focus {
            box-shadow: none;
        }
    }

    &:focus {
        border-bottom-color: $grey-lighter;
        // height: auto;
    }

    &.is-danger {
        border-color: $danger;

        + .icon-error:before {
            background-image: close($danger);
            background-position: top;
            background-repeat: no-repeat;
            background-size: 1rem;
            color: $danger;
            content: '';
        }
    }

    &.is-success {
        border-color: #f29400;

        + .icon-valid:before {
            background-image: checkmark($success);
            background-position: top;
            background-repeat: no-repeat;
            background-size: 1rem;
            color: $success;
            content: '';
        }
    }
}
