.title {
    font-weight: 600;
    margin: 0 0 1rem;

    @at-root h1#{&} {
        font-size: $size-1;
    }

    @at-root h2#{&} {
        font-size: $size-2;
    }

    @at-root h3#{&} {
        font-size: $size-3;
        font-weight: 700;
    }

    @at-root h4#{&} {
        font-size: $size-4;
    }

    @at-root h5#{&} {
        font-size: $size-5;
    }

    @at-root h6#{&} {
        font-size: $size-6;
        text-transform: uppercase;
    }

    &.has-icon {
        font-weight: normal;

        svg {
            display: inline-block;
            margin-right: 0.5em;
            vertical-align: middle;
        }
    }

    &.has-line {
        border-bottom: 3px solid $light;
        padding-bottom: 0.75em;
        text-transform: uppercase;
    }

    &.has-image {
        img {
            display: inline-block;
            margin-right: 0.5em;
            max-height: 62px;
            vertical-align: middle;
            width: auto;
        }
    }
}
