.is-truncated-touch {
    @media (max-width: 1023px) {

        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 25vw;

        @at-root .breadcrumb & {
            display: inline-block !important;
        }
    }
}
