.has-border-transparent {
    border-color: transparent;

    &.button.is-focused:not(:active),
    &.button:focus:not(:active) {
        box-shadow: none;
    }

    &:focus,
    &:hover {
        border-color: transparent;
    }
}
