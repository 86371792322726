.slider {
    &_items {
        display: grid;
        overflow: hidden;

        &--item {
            grid-area: 1 / 1 / 2 / 2;
            opacity: 0;
            transition: opacity 300ms ease-in-out;
            z-index: -99;

            &.active {
                opacity: 1;
                z-index: 0;
            }

            img {
                height: auto;
                width: 100%;
            }

            .button {
                margin-top: 1.5rem;
            }

            section {
                @include from($desktop) {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .title {
                @extend #{'.has-text-dark', '.has-text-weight-bold', '.is-size-2'};
            }

            .title + .subtitle {
                margin-top: 1rem;
                @extend #{'.has-text-dark', '.has-text-weight-semibold', '.is-size-4'};
            }
        }
    }

    @mixin indicator($background_color, $border_color, $active_color) {
        .slider_indicator {
            display: flex;
            justify-content: center;

            &--item {
                background: $background_color;
                border: 2px solid $border_color;
                border-radius: 50%;
                cursor: pointer;
                display: inline-block;
                font-size: 0;
                height: 17px;
                margin: 1rem 10px;
                width: 17px;

                &.active {
                    background-color: var(--theme);
                    border-color: var(--theme);
                    border-radius: 0 9px 9px 9px;
                    transform: rotate(45deg);

                    @at-root body:not([style^='--theme']) & {
                        background-color: $active_color;
                        border-color: $active_color;
                    }

                    @at-root body:not([style*=' --theme']) & {
                        background-color: $active_color;
                        border-color: $active_color;
                    }
                }
            }
        }
    }

    // Variants
    &--default {
        @include indicator($bone-health, $white, $orange);
        background-color: $bone-health;
        overflow: hidden;

        &_indicator {
            @include from($desktop) {
                margin-top: -72px;
            }
        }

        .slider_items--item {
            .columns {
                display: grid;

                @include from($desktop) {
                    display: flex;
                }
            }

            .column:last-child {
                order: -1;

                @include from($desktop) {
                    order: 1;
                }
            }
        }

        @include from($desktop) {
            .slider_indicator {
                justify-content: flex-end;
            }
        }
    }

    &--highlight {
        @include indicator($white, $white-darker, $orange);

        .tag-list {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            margin: 20px 0;

            li {
                align-items: center;
                display: flex;
                gap: 10px;
            }
        }
    }

    &--image {
        @include indicator($white, $white-darker, $orange);
    }
}
