.tag {
    &:not(body) {
        background-color: var(--tag-background);
        color: var(--tag-text);
        height: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        &:not(.is-post) {
            font-weight: 600;
        }

        &.is-post {
            border-radius: 0;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
            margin-bottom: 20px;
            padding: 1.5rem;

            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }
}
