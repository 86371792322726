:root {
    --typography-mobile-1: 1.75rem;
    --typography-mobile-2: 1.5rem;
    --typography-mobile-3: 1.25rem;
    --typography-mobile-4: 1.125rem;
    --typography-mobile-5: var(--typography-mobile-4);
    --typography-mobile-6: var(--typography-mobile-4);
}

@include mobile {
    .is-size-1,
    .title {
        font-size: var(--typography-mobile-1) !important;
    }

    .is-size-2,
    .subtitle {
        font-size: var(--typography-mobile-2) !important;
    }

    .is-size-3 {
        font-size: var(--typography-mobile-3) !important;
    }

    .is-size-4 {
        font-size: var(--typography-mobile-4) !important;
    }

    .is-size-5 {
        font-size: var(--typography-mobile-5) !important;
    }

    .is-size-6 {
        font-size: var(--typography-mobile-6) !important;
    }
}
