/*
- First, overwrite Bulma variables with custom values
- Then, add Bulma components at the end of this file
 */

$black: #000;
$danger: #ef4480;
$dark: #454545;
$grey: #6e6e6e;
$grey-lighter: #c8c8c8;
$label-color: $dark;
$light: #f0f0f0;
$primary: #f29309;
$primary-darker: #c17507;
$success: #39d6c5;
$text: $dark;
$white: #fff;

$block-spacing: 1rem;

$body-font-size: 16px;
$body-line-height: 1.65;

$button-border-width: 2px;
$button-ghost-color: $text;
$button-ghost-hover-color: inherit;

$card-footer-border-top: 0;

$dropdown-content-radius: 0;
$dropdown-content-shadow: 0 0 7px -1px rgba(#6e6e6e, 50%);

$family-sans-serif: 'Asap', sans-serif;

$help-size: 12px;

$input-placeholder-color: $text;

$label-color: $grey;
$label-weight: 400;

$navbar-dropdown-boxed-radius: 0;
$navbar-dropdown-boxed-shadow: $dropdown-content-shadow;
$navbar-dropdown-item-hover-background-color: transparent;
$navbar-dropdown-radius: 0;
$navbar-item-hover-background-color: transparent;
$navbar-item-hover-color: $black;

$pagination-active-border-color: $primary;
$pagination-active-color: $white;
$pagination-border-color: $light;
$pagination-color: $dark;
$pagination-current-background-color: $primary;
$pagination-current-border-color: $primary;
$pagination-current-color: $white;
$pagination-hover-border-color: $primary;
$pagination-hover-color: $white;

$size-1: 2rem;
$size-2: 1.5rem;
$size-3: 1rem;
$size-4: 1.53rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 14px;

$size-5-half: 1.125rem;

$tag-radius: 6666px;

@import '../../../../node_modules/bulma/bulma.sass';
