.content {
    a {
        @extend #{'.link'};
    }

    .button {
        @extend %button-has-theme;
    }

    figure {
        margin-left: 0;
        margin-right: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend #{'.title'};

        &:not(:first-child) {
            margin-top: 0;
        }
    }

    ol {
        margin-top: 0;
        @extend #{'.list'};

        &:not([type]) {
            list-style: none;
        }
    }

    table {
        @extend #{'.table'};
    }
}

/* #@! */

.content ol.slider_indicator,
.content ol.tabs-list,
.content ul.slider_indicator,
.content ul.tabs-list {
    padding-left: 0;

    li:before {
        content: none;
    }
}

.content-sources {
    word-break: break-all;
}

.content-sources ol {
    padding-left: 0;

    li {
        list-style-type: decimal;
        list-style-position: inside;
        margin-top: 0.25rem;

        &:before {
            content: none;
        }
    }
}
