.list-social {
    line-height: 0;
    list-style: none;
    margin-left: -5px;
    padding-left: 0;

    > li {
        display: inline-block;
        padding-left: 0.75rem;
        padding-right: 0.75rem;

        @include from($desktop) {
            padding-left: 0.45rem;
            padding-right: 0.45rem;
        }
    }

    .icon {
        height: 34px;
        width: 34px;

        @include from($desktop) {
            height: 32px;
            width: 32px;
        }
    }
}
