blockquote {
    footer {
        display: block;
        font-style: italic;

        &:before {
            content: '–';
        }
    }
}
