.call-to-action {
    &--default,
    &--image-text {
        background: linear-gradient(to right, $primary, $bone-health);

        .block,
        .content,
        .title {
            color: $white;

            strong {
                color: inherit;
            }
        }

        .button.is-primary {
            background-color: $white;
            border-color: $white;
            color: $primary;

            &:hover {
                background-color: $white-darker;
                border-color: $white-darker;
                color: $primary;
            }
        }
    }

    &--only-image {
        position: relative;

        [class^='mb-'],
        [class*=' mb-'] {
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            text-align: center;
            z-index: 1;
        }
    }

    img {
        max-height: calc(100% - #{$column-gap});
    }
}

.tab-content--inner .call-to-action {
    overflow: hidden;
}
