.tabs-has-content {
    @for $i from 0 through 50 {
        ##{tab-item}-#{$i}:checked ~ .tabs-content .#{tab-item}-#{$i} {
            display: block;
        }

        ##{tab-item}-#{$i}:checked ~ .tabs .#{tab-item}-#{$i} label {
            background-color: var(--theme);
            border-radius: $tag-radius;
            color: var(--theme-text);
        }
    }

    .tab-content {
        &--inner {
            padding: 2em;
        }

        &--outer {
            display: none;
        }
    }

    .tabs {
        background-color: var(--color-light-gray);
        border-radius: $tag-radius;

        &:not(:last-child) {
            margin-bottom: 0;
        }

        label {
            align-items: center;
            border: 0;
            cursor: pointer;
            display: flex;
            font-weight: $weight-semibold;
            justify-content: center;
            padding: 0.5em 1em;
            text-transform: uppercase;

            &:hover {
                color: var(--theme);
            }
        }
    }

    ul.tabs-list {
        margin-top: 0;
        padding-left: 0;

        li {
            margin-top: 0;
        }

        li:before {
            content: none;
        }
    }
}
