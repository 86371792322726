.availability {
    background: linear-gradient(90deg, #ff8000 0%, #ffdf00 100%);
    color: $white;

    &-link {
        svg {
            height: 38px;
        }
    }

    .input {
        border: 0;
        height: auto;
        padding: 1em 2em;
    }

    .list {
        li {
            margin-bottom: 0.5em;
        }

        li:before {
            background-color: $white;
        }
    }

    .title {
        color: inherit;
        margin-bottom: 1em;
    }
}
