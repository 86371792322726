.link {
    font-weight: bold;
    text-decoration: none;
    color: currentColor;
    transition: color 300ms;

    &:hover {
        color: $primary;
        transition: color 300ms;
    }
}

a {
    color: currentColor;
}

a.button {
    border-width: 2px;
    font-weight: $weight-semibold;

    &.is-outlined,
    &.is-primary {
        text-transform: uppercase;
    }

    &.is-outlined {
        background-color: $white;
        @extend %button-has-theme;
    }

    &.is-primary {
        min-height: var(--button-min-height);
        min-width: var(--button-min-width);
        padding-left: calc(2em - #{$button-border-width});
        padding-right: calc(2em - #{$button-border-width});
        @extend %button-has-theme;
    }

    &.is-secondary {
        border-radius: 0;
        border: 0;
        padding-left: 0;
        position: relative;

        &:after {
            background-color: var(--theme);
            bottom: 0;
            content: '';
            height: 3px;
            left: 0;
            position: absolute;
            transition: width 300ms;
            width: 0;

            @at-root body:not([style^='--theme']) & {
                background-color: $primary;
            }

            @at-root body:not([style*=' --theme']) & {
                background-color: $primary;
            }
        }

        &:hover:after {
            transition: width 300ms;
            width: 100%;
        }
    }
}
