// @import "bulma/elements/container.sass";
// @import "bulma/helpers/spacing.sass";

/* Buttons */
.cookie-modal__buttons {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

@media (min-width: 1024px) {
    .cookie-modal__buttons {
        flex-direction: row;
    }
}

.cookie-modal__button {
    align-items: center;
    background-color: var(--color-white);
    border-radius: 9999px;
    border: 2px solid var(--color-light-gray);
    color: currentColor;
    display: inline-flex;
    font-weight: 400;
    justify-content: center;
    min-height: var(--button-min-height);
    min-width: var(--button-min-width);
    padding: calc(0.5em - 2px) calc(2em - 2px);
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

.cookie-modal__button:focus,
.cookie-modal__button:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-white);
    font-weight: 600;
}

@media (min-width: 1024px) {
    .cookie-modal__button {
        width: auto;
    }
}

.cookie-modal__button:not(.hide) + .cookie-modal__button:not(.hide) {
    margin-left: 0;
    margin-top: 1rem;

    @media (min-width: 1024px) {
        margin-left: 1rem;
        margin-top: 0;
    }
}

.cookie-modal__button.primary,
#cookie-save {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-white);
    font-weight: 600;
}

.cookie-modal__button.primary:focus,
.cookie-modal__button.primary:hover,
#cookie-save:focus,
#cookie-save:hover {
    background-color: var(--color-primary-darker);
    border-color: var(--color-primary-darker);
}

.cookie-modal__button.primary:focus,
#cookie-save:focus {
    // box-shadow: 0 0 0 0.125em rgba(242, 147, 9, 0.25);
    // border-color: var(--color-primary);
}

.cookie-modal__button.hide {
    display: none;
}

/* Checkboxes */
.cookie-modal__check {
    left: 0;
    position: absolute;
    top: 0;
}

.cookie-modal__check svg {
    width: 1rem;
}

.cookie-modal__checkbox[type='checkbox'] {
    vertical-align: middle;
}

.cookie-modal__checkbox[type='checkbox']:checked {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
    stroke: var(--color-white);
}

.cookie-modal__checkbox[type='checkbox']:checked + .cookie-modal__check svg {
    stroke: var(--color-white);
}

.cookie-modal__checkbox {
    appearance: none;
    border: 2px solid var(--color-light-gray);
    border-radius: 2px;
    display: inline-block;
    height: 1rem;
    width: 1rem;
}

.cookie-modal__options {
    margin-top: 1rem;
}

.cookie-modal__option {
    cursor: pointer;
    display: block;
    position: relative;
}

@media (min-width: 1024px) {
    .cookie-modal__option {
        display: inline-block;
    }
}

.cookie-modal__option.disabled:hover {
    cursor: not-allowed;
}

.cookie-modal__option.disabled .cookie-modal__checkbox {
    background-color: var(--cm-light);
    border-color: var(--cm-light);
}

@media (min-width: 1024px) {
    .cookie-modal__option + .cookie-modal__option {
        margin-left: 1rem;
    }
}

/* Container and content */
.cookie-modal {
    background-color: var(--color-white);
    box-shadow: 0 0 7px -1px rgba(110, 110, 110, 0.5);
    bottom: 0;
    position: fixed;
    z-index: 100;
    @extend #{'.container', '.is-fluid'};

    &--hidden {
        display: none;
    }

    &__content {
        @extend #{'.container', '.py-4'};
    }

    &__text a {
        color: currentColor;
        font-weight: 600;
    }

    &__title {
        font-size: 1.5rem;
        font-weight: 700;
    }
}
