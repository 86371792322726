.message {
    border-radius: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 10%);

    &[class^='is-'],
    &[class*=' is-'] {
        .message-body {
            color: var(--color-text);
        }
    }

    &-body {
        background-color: var(--color-white);
        border-radius: 0;
        border: 0;
        padding: 1em 1.75em;
    }

    &-header {
        background-color: var(--theme);
        border-radius: 0;
        font-weight: 600;
        text-transform: uppercase;
        padding: 1em 1.75em;
    }
}
