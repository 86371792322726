input[type='submit'] {
    border-width: 2px;
    font-weight: $weight-semibold;

    &.is-primary {
        padding-left: calc(2em - #{$button-border-width});
        padding-right: calc(2em - #{$button-border-width});
        text-transform: uppercase;
        @extend %button-has-theme;
    }
}

.submit-has-icon {
    display: inline-block;
    position: relative;

    .button {
        text-indent: -666rem;
    }

    .icon {
        bottom: 0;
        left: 0;
        margin: auto;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }

    input[type='submit'].is-primary {
        width: calc(calc(#{$body-size} * 1) * calc(#{$body-line-height} * 1.5));
        padding-left: 0;
        padding-right: 0;
    }
}
