.search-result {
    box-shadow: 0 5px 10px 0 rgba(black, 10%), 0 5px 30px 0 rgba(white, 30%);

    .container {
        @include from($desktop) {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem;
        }
    }

    + .search-result {
        margin-top: 15px;
    }

    .block {
        @include from($desktop) {
            margin-bottom: 0;
            max-width: 75%;
        }
    }
}
