html[dir='rtl'] {
    a.dropdown-item,
    button.dropdown-item {
        padding-left: 3rem;
        padding-right: 1rem;
    }

    .breadcrumb.has-chevron-separator li + li:before {
        transform: rotate(180deg);
    }

    .card-footer {
        &-item:last-of-type > .tag {
            left: $card-content-padding;
            right: unset;
        }

        .icon {
            margin-left: 0.75rem;
            margin-right: unset;
        }
    }

    .control-checkbox {
        padding-left: unset;
        padding-right: calc(var(--checkbox-size) + 5px);

        .label:before {
            left: unset;
            right: 0;
        }

        input[type='checkbox'] {
            left: unset;
            right: 0;
        }
    }

    .control.has-icons-right .input,
    .control.has-icons-right .select select {
        padding-left: 2.5rem;
        padding-right: 1rem;
    }

    .cookie-modal {
        &__check {
            left: unset;
            right: 0;
        }

        &__button:not(.hide) + .cookie-modal__button:not(.hide) {
            margin-left: 0;
            margin-right: 0;
            margin-top: 1rem;

            @media (min-width: 1024px) {
                margin-left: 0;
                margin-right: 1rem;
                margin-top: 0;
            }
        }
    }

    .dropdown .icon-chevron-down:before {
        margin-left: unset;
        margin-right: 0.5rem;
    }

    .footer-bottom .has-text-right {
        text-align: left !important;
    }

    .navbar {
        &-dropdown a.navbar-item {
            @media (min-width: 1024px) {
                padding-right: 1.5rem;
                padding-left: 3rem;
            }
        }

        .logo {
            left: unset;
            right: 0;
        }
    }

    .post .column.is-10.is-offset-1 {
        @media (min-width: 769px) {
            margin-left: 0;
            margin-right: 8.33333%;
        }
    }

    .search-input .has-icons-right:after {
        left: 1rem;
        right: unset;
    }

    .tag.is-post:not(:last-child) {
        margin-left: 1rem;
        margin-right: unset;
    }
}
