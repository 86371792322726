.search-input {
    @at-root .main & {
        box-shadow: 0 0 6px 0 rgba(black, 20%);

        @include from($desktop) {
            margin-left: auto;
            margin-right: auto;
            width: 85%;
        }
    }

    .field {
        margin-bottom: 5px;
    }

    .has-icons-right {
        position: relative;

        &:after {
            background-image: lens(black);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            bottom: 0;
            content: '';
            height: 2em;
            margin: auto;
            pointer-events: none;
            position: absolute;
            right: 1rem;
            top: 0;
            width: 2em;
        }
    }

    .input {
        border: 0;
        height: 60px;
        padding: 1rem;
    }

    &-results {
        a {
            color: $text;
            display: block;
            padding: 0.5rem;
        }

        li + li {
            border-top: 3px solid #f5f5f5;
        }

        li:last-of-type {
            padding-top: 1rem;

            .icon {
                height: 1rem;
                width: 1rem;

                &.icon-chevron-right:before {
                    background-image: chevron-right(black);
                    margin-left: 1rem;
                }
            }
        }

        ul {
            background-color: white;
            padding: 1rem;
        }
    }

    :-ms-input-placeholder {
        color: $text;
    }

    ::-ms-input-placeholder {
        color: $text;
    }

    ::placeholder {
        color: $text;
        opacity: 1;
    }
}
