:root {
    --checkbox-size: 24px;

    --theme: var(--color-primary);
    --theme-darker: var(--color-primary-darker);
    --theme-text: var(--color-text);

    --button-min-height: 50px;
    --button-min-width: 225px;
}
