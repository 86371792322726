.accordion {
    overflow: hidden;

    &-content {
        &--inner {
            padding: 1.75em 2em 0.75em;
        }

        &--outer {
            max-height: 0;
            transition: max-height 300ms;
        }
    }

    &-item {
        margin-bottom: 1em;
        overflow: hidden;
        width: 100%;
    }

    &-label {
        background: linear-gradient(90deg, $primary 0%, $bone-health 100%);
        border-radius: $tag-radius;
        color: $white;
        cursor: pointer;
        display: flex;
        font-weight: $weight-semibold;
        justify-content: space-between;
        padding: 1em 2em;
        position: relative;
        text-transform: uppercase;

        .accordion-icons {
            position: absolute;
            right: 2em;

            .icon-chevron-up {
                display: none;
            }

            .icon-chevron-down {
                display: block;
            }
        }
    }

    input[type='radio'] {
        opacity: 0;
        position: absolute;
        z-index: -1;

        &:checked {
            + .accordion-label .accordion-icons {
                .icon-chevron-up {
                    display: block;
                }

                .icon-chevron-down {
                    display: none;
                }
            }

            ~ .accordion-content--outer {
                max-height: 100vh;
            }
        }
    }
}
