@media (max-width: 1407px) {
    .card-footer {
        flex-direction: column;
    }

    .card-footer-item {
        padding: 1.5rem;

        &.is-justify-content-end,
        &.is-justify-content-start {
            justify-content: center !important;
        }

        + .card-footer-item {
            padding-top: 0;
        }

        &:last-of-type > .tag {
            position: relative !important;
            right: unset !important;
        }
    }
}
