#header {
    .breadcrumb li.is-active a {
        color: $white;
    }

    .columns {
        @include until($desktop) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .dropdown .button {
        @include from($desktop) {
            color: var(--color-white);
        }
    }

    .has-background-primary .has-text-white a:hover {
        color: $text;
    }

    .header-bottom,
    .header-top {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .header-bottom {
        .breadcrumb.has-chevron-separator li + li:before {
            background-image: chevron-right($white);
        }
    }
}
