.pages {
    > ol {
        list-style: none;
        padding-left: 0;
        position: relative;

        @include from($desktop) {
            display: inline-block;
            margin-left: -1rem;
        }

        > li {
            line-height: 2;
            padding-left: 1rem;
            padding-right: 1rem;
            position: relative;
            vertical-align: top;

            &:last-of-type {
                padding-right: 0;
            }

            @include from($desktop) {
                display: inline-block;
            }
        }
    }

    a {
        font-weight: 600;
        text-transform: uppercase;
    }

    .subpages {
        display: block;
        list-style: none;

        a {
            font-weight: 400;
            text-transform: none;
        }
    }
}

.pages-link-icons {
    display: none !important;

    // display: block;
    // position: absolute;
    // right: 5px;
    // top: 0.5rem;
    //
    // @include from($desktop) {
    //     display: none;
    // }
    //
    // @at-root .icon-switched#{&} {
    //     .icon-plus {
    //         display: none;
    //     }
    //
    //     .icon-minus {
    //         display: block;
    //         fill: $primary;
    //     }
    // }
    //
    // .icon {
    //     height: 1rem;
    //     width: 1rem;
    // }
    //
    // .icon-plus {
    //     display: block;
    //
    //     &:before {
    //         background-image: plus($anthracite);
    //     }
    // }
    //
    // .icon-minus {
    //     display: none;
    //
    //     &:before {
    //         background-image: minus($primary);
    //     }
    // }
}
