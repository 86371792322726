// Darker color versions (20%) via https://pinetools.com/darken-color

:root {
    --color-anthracite: #{$anthracite};
    --color-anthracite-darker: #{$anthracite-darker};

    --color-black: #{$black};
    --color-black-darker: #{$black-darker};

    --color-bone-health: #{$bone-health};
    --color-bone-health-darker: #{$bone-health-darker};

    --color-cardio-vascular: #{$cardio-vascular};
    --color-cardio-vascular-darker: #{$cardio-vascular-darker};

    --color-celiac-disease: #{$celiac-disease};
    --color-celiac-disease-darker: #{$celiac-disease-darker};

    --color-colon-cancer-prevention: #{$colon-cancer-prevention};
    --color-colon-cancer-prevention-darker: #{$colon-cancer-prevention-darker};

    --color-infections: #{$infections};
    --color-infections-darker: #{$infections-darker};

    --color-inflamatory-bowel-disease: #{$inflamatory-bowel-disease};
    --color-inflamatory-bowel-disease-darker: #{$inflamatory-bowel-disease-darker};

    --color-light-gray: #{$light-gray};
    --color-light-gray-darker: #{$light-gray-darker};

    --color-metabolism: #{$metabolism};
    --color-metabolism-darker: #{$metabolism-darker};

    --color-orange: #{$orange};
    --color-orange-darker: #{$orange-darker};

    --color-prostate-bladder: #{$prostate-bladder};
    --color-prostate-bladder-darker: #{$prostate-bladder-darker};

    --color-stomach-colon: #{$stomach-colon};
    --color-stomach-colon-darker: #{$stomach-colon-darker};

    --color-therapy-monitoring: #{$therapy-monitoring};
    --color-therapy-monitoring-darker: #{$therapy-monitoring-darker};

    --color-white: #{$white};
    --color-white-darker: #{$white-darker};

    --color-womens-health: #{$womens-health};
    --color-womens-health-darker: #{$womens-health-darker};

    --color-active: var(--color-primary);
    --color-active-darker: var(--color-primary-darker);
    --color-link: var(--color-primary);
    --color-link-darker: var(--color-primary-darker);
    --color-primary: var(--color-orange);
    --color-primary-darker: var(--color-orange-darker);
    --color-text: var(--color-anthracite);
    --color-text-darker: var(--color-anthracite-darker);
}
