.dropdown {
    z-index: 31;

    &-content {
        padding-bottom: 0;
        padding-top: 0;
    }

    &-item {
        font-size: inherit;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        position: relative;

        @at-root a#{&} {
            &:hover,
            &.is-active {
                background-color: #fbd4aa;
                color: $anthracite;

                &:not(.button) {
                    color: inherit;
                }
            }
        }

        &:not(:last-of-type):after {
            background-color: $light-gray;
            bottom: -1px;
            content: '';
            height: 2px;
            left: 1rem;
            position: absolute;
            width: calc(100% - 2rem);
        }
    }

    &-menu {
        margin-top: -2px;
        min-width: calc(12rem + 3px);
        padding-top: 0;

        @at-root html[dir='ltr'] & {
            left: 0;
        }

        @at-root html[dir='rtl'] & {
            right: 0;
        }
    }

    .button {
        background-color: transparent;
        border: 0;
        border-bottom: 2px solid $grey-lighter;
        border-radius: 0;

        @at-root html[dir='ltr'] & {
            padding-left: 0;
        }

        @at-root html[dir='rtl'] & {
            padding-right: 0;
        }
    }

    .icon-chevron-down:before {
        background-image: chevron-down($anthracite);
        width: 15px;

        @at-root html[dir='ltr'] & {
            margin-left: 0.5em;
        }

        @at-root html[dir='rtl'] & {
            margin-right: 0.5em;
        }
    }

    span {
        font-weight: 400;
    }
}
