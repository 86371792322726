%button-has-theme {
    background-color: var(--theme);
    border-color: var(--theme);
    color: var(--theme-text);

    &:hover {
        background-color: var(--theme-darker);
        border-color: var(--theme-darker);
        color: var(--theme-text);
    }
}

.button-has-theme {
    @extend %button-has-theme;
}
