/*
Darker colors (20%) via
https://pinetools.com/darken-color
 */

$anthracite-darker: #222;
$anthracite: #454545;

$black-darker: #000;
$black: #000;

$bone-health-darker: #e0af00;
$bone-health: #ffcd19;

$cardio-vascular-darker: #aa0f12;
$cardio-vascular: #d51317;

$celiac-disease-darker: #e83f18;
$celiac-disease: #ee7053;

$colon-cancer-prevention-darker: #004b81;
$colon-cancer-prevention: #005ea2;

$infections-darker: #b60756;
$infections: #e4096c;

$inflamatory-bowel-disease-darker: #1d8fbc;
$inflamatory-bowel-disease: #32aedf;

$light-gray-darker: #c0c0c0;
$light-gray: #f0f0f0;

$metabolism-darker: #527828;
$metabolism: #679632;

$orange-darker: #c17507;
$orange: #f29309;

$prostate-bladder-darker: #007b75;
$prostate-bladder: #009a93;

$stomach-colon-darker: #002f64;
$stomach-colon: #003b7d;

$therapy-monitoring-darker: #8ca106;
$therapy-monitoring: #afca08;

$white-darker: #ccc;
$white: #fff;

$womens-health-darker: #591b68;
$womens-health: #702283;
