.card {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.is-news {
        .card-image {
            @extend #{'.has-object-fit-cover'};
        }
    }

    &.is-product {
        .card-image {
            @extend #{'.has-object-fit-contain'};
        }
    }

    &-content {
        flex: auto;
    }

    &-footer {
        min-height: calc(calc(#{$card-content-padding} * 2) + 1rem);
    }

    &-footer .icon {
        height: 2rem;
        width: 2rem;
    }

    &-footer-item {
        padding: 1rem $card-content-padding;
    }

    &-footer-item:last-of-type {
        position: relative;

        > .tag {
            position: absolute;
            right: $card-content-padding;
        }
    }

    &-image {
        img:first-child {
            border-radius: 0;
        }
    }

    .title.is-size-5 {
        line-height: 1.65;
    }
}
