$checkbox-size: 24px;

.control-checkbox {
    padding-left: calc(#{$checkbox-size} + 5px);
    position: relative;

    &.is-danger {
        .label:before {
            border-color: $danger;
        }
    }

    &.is-success {
        .label:before {
            border-color: $success;
        }
    }

    input[type='checkbox'] {
        height: $checkbox-size;
        left: 0;
        margin: 0;
        opacity: 0;
        position: absolute;
        width: $checkbox-size;

        &:checked + .label:before {
            background-image: checkmark($primary);
            border-color: $primary;
        }
    }

    > .label {
        line-height: $checkbox-size;

        &:before {
            background-position: center;
            background-repeat: no-repeat;
            background-size: calc(100% - 10px);
            border: 2px solid $dark;
            content: '';
            cursor: pointer;
            height: $checkbox-size;
            left: 0;
            position: absolute;
            width: $checkbox-size;
        }

        a {
            font-weight: bold;
        }

        .asterisk,
        p {
            display: inline;
        }
    }

    .control-checkbox-relatives {
        margin-left: calc(calc(#{$checkbox-size} + 5px) * -1);
    }
}
