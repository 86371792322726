.mirror-teaser {
    background: var(--color-primary);
    background: linear-gradient(180deg, var(--color-primary) 0%, var(--color-bone-health) 100%);
    color: var(--color-white);

    @include from($desktop) {
        background: var(--color-bone-health);
        background: radial-gradient(circle, var(--color-bone-health) 0%, var(--color-primary) 100%);
        display: flex;
    }

    &-item {
        padding: 2.2rem 1rem;
        min-width: 50%;

        @include from($desktop) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto 1fr;
            gap: 0 0;
            grid-auto-flow: row;
            padding: 1.5rem 3rem;

            .block {
                grid-area: block;
            }

            .image {
                align-self: center;
                grid-area: image;
            }

            .title {
                grid-area: title;
            }
        }
    }

    &-item:first-of-type {
        @include from($desktop) {
            grid-template-areas:
                'title image'
                'block image';

            .image {
                justify-self: end;
            }
        }
    }

    &-item:last-of-type {
        @include from($desktop) {
            grid-template-areas:
                'image title'
                'image block';

            .image {
                justify-self: start;
            }
        }
    }

    .image {
        margin: auto auto 1.5rem;

        @include from($desktop) {
            margin: auto;
        }
    }

    .image > img {
        background-color: var(--color-white);
        border-radius: 50%;
        max-width: 144px;
    }

    .title {
        color: inherit;
        margin: 0 0 1rem;
    }
}
