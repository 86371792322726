a.link-download {
    .icon {
        height: 24px;
        width: 24px;

        &:before {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            content: '';
            display: block;
            height: inherit;
            width: inherit;
        }

        &-document-jpg:before {
            background-image: document-jpg();
        }

        &-document-pdf:before {
            background-image: document-pdf();
        }

        &-document-png:before {
            background-image: document-png();
        }

        &-document-word:before {
            background-image: document-word();
        }

        &-text .icon:not(:last-child) {
            margin-right: 0.75em;
        }
    }
}
