.text-image {
    .image {
        margin-bottom: 2rem;

        @include from($widescreen) {
            float: right;
            margin-left: 2rem;
            max-width: 50%;
        }
    }
}
