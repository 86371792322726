/*
Fonts via
https://google-webfonts-helper.herokuapp.com/fonts
 */

$fontPath: '../fonts';

@font-face {
    font-display: swap;
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    src: url('#{$fontPath}/asap/asap-v24-latin-regular.eot');
    src: local(''),
        url('#{$fontPath}/asap/asap-v24-latin-regular.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}/asap/asap-v24-latin-regular.woff2') format('woff2'),
        url('#{$fontPath}/asap/asap-v24-latin-regular.woff') format('woff'),
        url('#{$fontPath}/asap/asap-v24-latin-regular.ttf') format('truetype'),
        url('#{$fontPath}/asap/asap-v24-latin-regular.svg#Asap') format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    src: url('#{$fontPath}/asap/asap-v24-latin-600.eot');
    src: local(''),
        url('#{$fontPath}/asap/asap-v24-latin-600.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}/asap/asap-v24-latin-600.woff2') format('woff2'),
        url('#{$fontPath}/asap/asap-v24-latin-600.woff') format('woff'),
        url('#{$fontPath}/asap/asap-v24-latin-600.ttf') format('truetype'),
        url('#{$fontPath}/asap/asap-v24-latin-600.svg#Asap') format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    src: url('#{$fontPath}/asap/asap-v24-latin-700.eot');
    src: local(''),
        url('#{$fontPath}/asap/asap-v24-latin-700.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}/asap/asap-v24-latin-700.woff2') format('woff2'),
        url('#{$fontPath}/asap/asap-v24-latin-700.woff') format('woff'),
        url('#{$fontPath}/asap/asap-v24-latin-700.ttf') format('truetype'),
        url('#{$fontPath}/asap/asap-v24-latin-700.svg#Asap') format('svg');
}
