.section {
    &.has-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &.has-border {
        box-shadow: 0 5px 10px -5px rgba(black, 0.1), 0 5px 30px -5px rgba(white, 0.3);
    }
}
