#footer {
    .container {
        @include until($desktop) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .footer-bottom,
    .footer-top {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .footer-text {
        margin-top: $block-spacing;
        padding-left: calc(var(--footerLogoXOffset) + 0.75rem);
        @extend #{'.block'};

        a {
            @extend #{'.has-text-weight-bold'};
        }
    }

    .footer-top {
        .column:first-of-type {
            @extend #{'.is-one-third'};
        }
    }

    .has-text-right {
        @include until($desktop) {
            text-align: center !important;
        }
    }

    .link.is-active {
        font-weight: 600;
    }

    .pages {
        @extend #{'.has-text-right'};

        ol {
            @extend #{'.has-text-left'};
        }
    }
}
