:root {
    --bullet-size-ol: 32px;
    --bullet-size-ul: 12px;
}

.list:not(.is-unstyled) {
    list-style: none;

    @at-root html[dir='ltr'] & {
        margin-left: 0;
    }

    @at-root html[dir='rtl'] & {
        margin-right: 0;
    }

    ol,
    ul {
        list-style: none;
    }

    @at-root #{selector-append(ol, &)} {
        counter-reset: item;
        margin-top: calc(1rem * -1);

        @at-root html[dir='ltr'] & {
            padding-left: calc(var(--bullet-size-ol) + 1rem);
        }

        @at-root html[dir='rtl'] & {
            padding-right: calc(var(--bullet-size-ol) + 1rem);
        }

        li {
            counter-increment: item;
            margin-top: 1rem;
            position: relative;

            &:before {
                background-color: var(--color-anthracite);
                border-radius: 50%;
                color: var(--color-white);
                content: counter(item);
                height: var(--bullet-size-ol);
                line-height: var(--bullet-size-ol);
                position: absolute;
                text-align: center;
                top: -3px;
                width: var(--bullet-size-ol);

                @at-root html[dir='ltr'] & {
                    left: calc(calc(var(--bullet-size-ol) + 1rem) * -1);
                }

                @at-root html[dir='rtl'] & {
                    right: calc(calc(var(--bullet-size-ol) + 1rem) * -1);
                }
            }
        }

        ol,
        ul {
            @at-root html[dir='ltr'] & {
                padding-left: calc(var(--bullet-size-ol) + 1rem);
            }

            @at-root html[dir='rtl'] & {
                padding-right: calc(var(--bullet-size-ol) + 1rem);
            }
        }
    }

    @at-root #{selector-append(ul, &)} {
        margin-top: calc(0.5rem * -1);

        @at-root html[dir='ltr'] & {
            padding-left: calc(var(--bullet-size-ul) + 1rem);
        }

        @at-root html[dir='rtl'] & {
            padding-right: calc(var(--bullet-size-ul) + 1rem);
        }

        &:not(.no-theme) li:before {
            background-color: var(--theme);
        }

        li {
            margin-top: 0.5rem;
            position: relative;

            &:before {
                border-radius: 50%;
                content: '';
                height: var(--bullet-size-ul);
                position: absolute;
                top: 6px;
                width: var(--bullet-size-ul);

                @at-root html[dir='ltr'] & {
                    left: calc((var(--bullet-size-ul) + 1rem) * -1);
                }

                @at-root html[dir='rtl'] & {
                    right: calc((var(--bullet-size-ul) + 1rem) * -1);
                }
            }
        }

        ol,
        ul {
            margin-top: 0.5rem;
            @at-root html[dir='ltr'] & {
                padding-left: calc(var(--bullet-size-ul) + 0.5rem);
            }

            @at-root html[dir='rtl'] & {
                padding-right: calc(var(--bullet-size-ul) + 0.5rem);
            }
        }
    }
}
