.video {
    @extend #{'.image, .is-16by9'};

    &-disclaimer {
        background-color: $white;
        bottom: 0;
        height: 100%;
        left: 0;
        padding: 1rem;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;

        .button {
            @extend %button-has-theme;
        }
    }

    iframe {
        @extend #{'.has-ratio'};
    }
}
