.table {
    &.is-pricing {
        width: 100%;

        td,
        th {
            border: 0;
            padding: 1em 0;

            &:last-of-type {
                text-align: right;
            }
        }

        tbody {
            td,
            th {
                padding: 0;
            }
        }

        tfoot {
            tr.has-text-grey {
                &:first-of-type td {
                    padding-bottom: 0;
                }

                &:not(:first-of-type) td {
                    padding-top: 0;
                }
            }

            tr:last-of-type td {
                border-top: 2px solid $light;
            }
        }
    }
}
