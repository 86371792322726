.section-category {
    box-shadow: 0 5px 10px 0 rgba(black, 0.1), 0 5px 30px 0 rgba(white, 0.3);
    margin-bottom: 1rem;

    &:last-of-type {
        box-shadow: none;
        margin-bottom: 0;
    }

    &.has-background-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .content + .button {
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
}
