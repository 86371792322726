.icon-text {
    .block {
        flex: 1;
    }

    .icon {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;

        &-heart {
            background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='5.5 6.5 21.65 19.27'%3E%3Ctitle%3Eico min spenden%3C/title%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M16.32,23.2628571 C16.4809524,23.2628571 16.6171429,23.2071429 16.7285714,23.0957143 L16.7285714,23.0957143 L22.5135714,17.5242857 C23.9311905,16.1066667 24.64,14.7138095 24.64,13.3457143 C24.64,11.9838095 24.2469048,10.9190476 23.4607143,10.1514286 C22.6745238,9.38380952 21.5880952,9 20.2014286,9 C19.817619,9 19.4260714,9.06654762 19.0267857,9.19964286 C18.6275,9.3327381 18.2560714,9.5122619 17.9125,9.73821429 C17.5689286,9.96416667 17.2733333,10.1761905 17.0257143,10.3742857 C16.7780952,10.572381 16.5428571,10.7828571 16.32,11.0057143 C16.0971429,10.7828571 15.8619048,10.572381 15.6142857,10.3742857 C15.3666667,10.1761905 15.0710714,9.96416667 14.7275,9.73821429 C14.3839286,9.5122619 14.0125,9.3327381 13.6132143,9.19964286 C13.2139286,9.06654762 12.822381,9 12.4385714,9 C11.0519048,9 9.96547619,9.38380952 9.17928571,10.1514286 C8.39309524,10.9190476 8,11.9838095 8,13.3457143 C8,13.7604762 8.0727381,14.187619 8.21821429,14.6271429 C8.36369048,15.0666667 8.52928571,15.4411905 8.715,15.7507143 C8.90071429,16.0602381 9.11119048,16.3620238 9.34642857,16.6560714 C9.58166667,16.950119 9.75345238,17.1528571 9.86178571,17.2642857 C9.97011905,17.3757143 10.0552381,17.4561905 10.1171429,17.5057143 L10.1171429,17.5057143 L15.9114286,23.0957143 C16.0228571,23.2071429 16.1590476,23.2628571 16.32,23.2628571 Z' stroke='%236A3993' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
        }

        &-lens {
            background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='7.68 8.4 17.02 15.02'%3E%3Ctitle%3Eico min suche%3C/title%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-2' transform='translate(8.186080, 8.901209)' stroke='%236A3993'%3E%3Ccircle id='Oval' cx='6' cy='6' r='5.5'/%3E%3Cline x1='10.5' y1='9.5' x2='15.5' y2='13.5' stroke-linecap='round'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
    }
}
