.card-horizontal {
    border-radius: 0;
    box-shadow: 0 5px 10px -5px rgba(black, 0.1), 0 5px 30px -5px rgba(white, 0.3);
    margin-bottom: 1rem;
    @extend #{'.card'};

    .card-image {
        display: none;
        grid-area: card-image;

        @include from($tablet) {
            display: block;
            padding-right: 5%;
        }
    }

    .card-content {
        grid-area: card-content;

        ol,
        ul {
            columns: 2;
        }
    }

    .card-footer {
        grid-area: card-footer;
    }

    .container {
        display: grid;
        gap: 0 0;
        grid-auto-flow: row;
        grid-template-areas:
            'card-image'
            'card-content'
            'card-footer';
        grid-template-columns: 100% 1fr;
        padding-bottom: 2rem;
        padding-top: 2rem;
        width: 100%;

        @include from($tablet) {
            grid-template-areas:
                'card-image card-content'
                'card-image card-footer';
            grid-template-columns: 33.333% 1fr;
        }

        @include from($desktop) {
            grid-template-areas:
                'card-image card-content'
                'card-image card-footer';
            grid-template-columns: 25% 1fr;
        }
    }

    .read-more {
        font-weight: 500;
        text-transform: uppercase;
    }
}

.section.is-list {
    padding-left: 0;
    padding-right: 0;
}
