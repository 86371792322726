.notification {
    border-radius: 0;

    &.has-text-danger {
        color: #ef4480;
    }

    &.has-text-success {
        color: #76c54d;
    }
}
