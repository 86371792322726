.breadcrumb {
    padding: 0.7rem 0;

    &.has-chevron-separator {
        li + li:before {
            background-image: chevron-right($anthracite);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: inline-block;
            height: 10px;
            padding: 0 1rem;
            width: 10px;
        }
    }

    .is-active a.link {
        font-weight: bold;
    }

    a {
        color: currentColor;

        &.link {
            font-weight: normal;
        }

        &:hover {
            color: inherit;
        }
    }
}
