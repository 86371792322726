.navbar-end {
    .dropdown .button .span {
        &:not(.icon) {
            @at-root html[dir='ltr'] & {
                order: 0;
            }

            @at-root html[dir='rtl'] & {
                order: 1;
            }
        }

        &.icon {
            @at-root html[dir='ltr'] & {
                order: 1;
            }

            @at-root html[dir='rtl'] & {
                order: 0;
            }
        }
    }
}
