.image {
    position: relative;

    &.is-100x100 {
        height: 100px;
        width: 100px;
    }

    &.is-144x144 {
        height: 144px;
        width: 144px;
    }

    &-caption {
        background-color: $white;
        bottom: 0;
        padding: 5px 10px;
        position: absolute;
        @extend #{'.is-size-7'};
    }

    img {
        object-fit: cover;
    }
}
